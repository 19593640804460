$primary: #C2A304;
$secondary: #31424D;
$light-grey: #ededed;
$purple: #7c4f6a;
$navy: #31414a;
$gold: #c59E11;
$breakpoint-xs: 450px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
