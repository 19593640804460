@import "sass/variables";
@import "sass/mixins";

.parallax {
  height: 1000px;
  @media only screen and (min-width: 600px) {
    height: 700px;
  }
  img {
    max-width: 100%;
  }
}

.avatar.avatar-xl {
  font-size: 1.6rem;
  height: 12rem;
  width: 12rem;
}

.about-container {
  width: 100%;
}

.about-company {
  position: absolute;
  background-color: $secondary;
  color: white;
  padding: 50px;
  top: 200px;
}

.meet-team {
  background-color: $light-grey;
}

.meet-header {
  padding: 50px;
  color: white;
  background-color: $gold;
  text-align: center;
}

.team-member {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .img {
    text-align: center;
    width: auto;
    @media only screen and (min-width: 600px) {
      width: 30%;
    }

    .avatar {
      box-shadow: 0 0.25rem 2rem rgba(48,55,66,.15);
      border-radius: 12%;
      background-color: rgb(237, 237, 237);

      img {
        border-radius: 12%;
      }
    }

    span {
      font-size: 100px;
      color: #31424D;
      line-height: 12rem;
    }
  }

  .info {
    width: 70%;

    h6 {
      font-style: italic;
    }
  }

  @include breakpoint(md) {
    flex-direction: row;
  }
}
