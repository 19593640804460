@import "sass/variables";
@import "sass/mixins";

.home-container {
  width: 100%;
}

.home-header {
  padding: 50px;
  color: white;
  background-color: $navy;
  text-align: center;
  span {
    font-weight: bold;
    font-size: 20px;
  }
  h1 {
    font-weight: bold;
  }
}

.gold {
  background-color: $gold;
}

.home-bar {
  position: absolute;
  background-color: rgba(0,0,0,0.7);
  color: white;
  padding: 50px;
  top: 200px;
  width: 100%;

  h4 {
    font-weight: bold;
    text-align: right;
    @media only screen and (min-width: 600px) {
    }
  }
}

.it4eLogo {
  height: 200px;
}

#home-about-container {
  .parallax {
    height: 900px;
    @media only screen and (min-width: 600px) {
      //height: 1800px;
    }
  }
}

#home-solutions-container {
  .parallax {
    height: 3300px;
    @media only screen and (min-width: 600px) {
      height: 2100px;
    }
    img {
      max-width: 100%;
    }
  }
  div:nth-child(3) {
    transform: translate(0%, 120%) !important;
  }
  div:nth-child(4) {
    transform: translate(0%, 240%) !important;
  }
  div:nth-child(5) {
    transform: translate(0%, 362%) !important;
  }
  div:nth-child(6) {
    transform: translate(0%, 492%) !important;
  }
  div:nth-child(7) {
    transform: translate(0%, 624%) !important;
  }

}

.home-solutions-bar {
  padding: 0px;
}
.textAlignRight {
  text-align: right;
}

.btn {
  color: $gold;
  background-color: $navy;
  border-color: white;
  color: white;
  &:hover {
    color: white;
    background-color: $gold;
    border-color: $gold;
    a {
      color: white;
    }
  }
  a {
    text-decoration: none;
    color: white;
    font-weight: bold;
    &:hover {
      color: white;
    }
  }
}

.homeContentColumn {
  display: flex;
  .homeInnerContentColumn {
    padding: 15px;
    margin: auto;
    .categoryTitle {
      font-weight: bold;
      font-size: 28px;
    }
  }
}

.contentSubTag {
  font-size: 17px;
}

.transformedGrid {
  transform: scale(1.75);
  margin: 0 !important;
  width: 100% !important;
  .liLeft {
    margin-left: 20% !important;
  }
  li:nth-child(6n+1), #grid li:nth-child(6n+2), #grid li:nth-child(6n+3) {
    transform: translateX(80%) rotate(-60deg) skewY(30deg) !important;
  }
  span {
    font-size: 17px !important;
    @media only screen and (min-width: 360px) {
      font-size: 11px !important;
    }
    @media only screen and (min-width: 1200px) {
      font-size: 20px !important;
    }
  }
}