@import "sass/variables";
@import "sass/mixins";

header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.sticky-navbar {
  display: inherit;
  position: relative;
  height: 80px;
  width: 100%;
  transition: 0.5s ease;
  //color: white;
  background-color: white;

  .large-nav {
    display: none;

    @include breakpoint(md) {
      padding: 0;
      display: inline-block;
      float: right;
      margin: 0 5% 0 0;
    }

    .item {
      list-style: none;
      display: inline-block;
      font-size: 18px;
      padding: 5px;
      font-weight: 300;
      line-height: 80px;
      margin-right: 20px;
      transition: 0.25s ease;

      &:hover {
        cursor: pointer;
      }

      a {
        text-decoration: none;
        color: inherit;
      }
    }
  }

  .logo {
    float: left;
    display: inline-block;
    margin-top: 5px;
    margin-left: 5%;

    img {
      height: auto;
      transition: 0.5s ease;
    }

    .logoImg {
      width: 90px;
    }

    .sapLogo {
      width: 70px;
      margin: 0 0 0 20px;
      &:hover {
        transform: scale(4);
        margin-top: 110px;
      }
    }
    
    :last-child {
      float: right;
      //margin: 20px;
    }
  }
}

.scrolled {
  transition: 0.5s ease;

  &.sticky-navbar {
    height: 55px;
    background-color: white;
    //color: #5755d9;
    transition: 0.5s ease;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);

    .large-nav .item {
      line-height: 40px;
      transition: 0.1s ease;
      font-weight: bold;
    }

    .logo {
      float: left;
      display: inline-block;
      margin-top: 0px;
      margin-left: 5%;

      img {
        height: auto;
        transition: 0.5s ease;
      }

      .logoImg {
        width: 60px;
      }

      .sapLogo {
        width: 50px;
      }

      :last-child {
        float: right;
        margin: 10px;
      }
    }
  }
}

.sidenav {
  transition: 0.5s ease;
  height: 100vh;
  width: 0;
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  text-align: right;
  position: absolute;
  right: 0;
  top: 0;
  margin: 0 0;

  &.expanded {
    transition: 0.5s ease;
    height: 100vh;
    width: 275px;
  }

  @include breakpoint(md) {
    display: none;
  }

  .nav-item {
    margin-top: 1rem !important;
    font-size: 32px;
    transition: 0.5s ease;
  }
}

.active {
  color: $primary !important;
}

.nav-menu,
.expand-button {
  @include breakpoint(md) {
    display: none;
  }
}

.nav-menu {
  position: absolute;
  top: 15px;
  right: 15px;
}

.close-menu {
  margin: 20px;

  &:hover {
    cursor: pointer;
  }
}