@import "sass/variables";
@import "sass/mixins";

.avatar.avatar-xl {
  font-size: 1.6rem;
  height: 12rem;
  width: 12rem;
}

.solution-container {
  width: 100%;
  overflow-x: hidden;
}

.solution-bar {
  position: absolute;
  background-color: $primary;
  color: white;
  padding: 50px;
  top: 200px;
  width: 100%;
}

a {
  text-decoration: none;
}

/* Begin hexagonal grid styles */

#grid {
  
  margin: 0 auto;
  width: 95%;
  padding: 20px 2%;
  -o-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;

  @media only screen and (min-width: 600px) {
    margin: auto;
    width: 70%;
  }

  .clear:after {
    content: "";
    display: block;
    clear: both;
  }

  li {
    position: relative;
    float: left;
    width: 27.85714285714286%;
    padding: 0 0 32.16760145166612% 0;
    list-style-type: none;
    -o-transform: rotate(-60deg) skewY(30deg);
    -moz-transform: rotate(-60deg) skewY(30deg);
    -webkit-transform: rotate(-60deg) skewY(30deg);
    -ms-transform: rotate(-60deg) skewY(30deg);
    transform: rotate(-60deg) skewY(30deg);
    overflow: hidden;
    visibility: hidden;
    cursor: pointer;
    &:hover {
      animation: animate 3s linear infinite;
      visibility: visible;
      z-index: 4;
    }
    * {
      visibility: visible;
    }
    @mixin dark-overlay {
      &:after{
        content: "";
        width: 100%;
        height: auto;
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
      }
    }
    .hexagon {
      @include dark-overlay;
      z-index: 1;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      //min-height: 197px;
      width: 100%;
      //min-width: 170px;
      -o-transform: skewY(-30deg) rotate(60deg);
      -moz-transform: skewY(-30deg) rotate(60deg);
      -webkit-transform: skewY(-30deg) rotate(60deg);
      -ms-transform: skewY(-30deg) rotate(60deg);
      transform: skewY(-30deg) rotate(60deg);
      overflow: hidden;
      background: none;
      img {
        left:-100%;
        right:-100%;
        height:100%;
        width: auto;
        margin: 0 auto;
        position: absolute;
        -o-transition: all 0.6s linear 0s;
        -moz-transition: all 0.6s linear 0s;
        -ms-transition: all 0.6s linear 0s;
        -webkit-transition: all 0.6s linear 0s;
        transition: all 0.6s linear 0s;
      }
      span {
        font-size: 13px;
        font-weight: bold;
        color: white;
        position: absolute;
        z-index: 3;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        @media screen and (min-width:600px) {
          font-size: 32px;
        }
      }
      &:after {
        background:rgba(0, 0, 0, 0.5);
      }
    }
    &:nth-child(3n+2) {
      margin: 0 1%;
    }
    &:nth-child(6n+4), &:nth-child(6n+5), &:nth-child(6n+6) {
      margin-top: -6.9285714285%;
    }
    &:nth-child(6n+1), &:nth-child(6n+2), &:nth-child(6n+3) {
      -o-transform: translateX(50%) rotate(-60deg) skewY(30deg);
      -moz-transform: translateX(50%) rotate(-60deg) skewY(30deg);
      -webkit-transform: translateX(50%) rotate(-60deg) skewY(30deg);
      -ms-transform: translateX(50%) rotate(-60deg) skewY(30deg);
      transform: translateX(50%) rotate(-60deg) skewY(30deg);
    }
    &:nth-child(6n+1) {
      margin-left: 0.5%;
    }
  }
}

/* End hexagonal grid styles */

/* Media Queries */

@media only screen and (max-width: 600px) {
  body {
    font-size: 14px;
  }
}

/* Begin custom styles */

@keyframes animate {
  0% {
    box-shadow: 0 0 0 0 rgba(197, 158, 17, .7), 0 0 0 0 rgba(197, 158, 17, .7);
  }
  40% {
    box-shadow: 0 0 0 50px rgba(197, 158, 17, 0), 0 0 0 0 rgba(197, 158, 17, .7);
  }
  80% {
    box-shadow: 0 0 0 50px rgba(197, 158, 17, 0), 0 0 0 30px rgba(197, 158, 17, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(197, 158, 17, 0), 0 0 0 30px rgba(197, 158, 17, 0);
  }
}

.spaceColumn {
  background-color: white;
}

.solutionColumns {
  min-height: 300px;
  &:nth-child(odd) {
    color: $primary;
    background-color: #ededed;
  }
  &:nth-child(even) {
    color: #ededed;
    background-color: $secondary;
  }
}

.solutionsContentColumn {
  display: flex;
  min-height: 163px;
  
  .solutionsInnerContentColumn {
    padding: 15px;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    max-width: 800px;
    span {
      margin: 15px 0;
    }
    .categoryTitle {
      font-weight: bold;
      font-size: 28px;
    }
  }
}

.parallax img {
  min-height: 120%;
}

.textAlignCenter {
  text-align: center;
}

.sapBadges {
  width: 200px;
  height: 200px;
  padding-right: 8px;
  padding-left: 8px;
  display: inline-block;
}

/* End custom styles */