@import "sass/mixins";
@import "sass/helpers";
@import "sass/variables";

body {
  top: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  padding-top: 80px;
  //background-color: #dce2ed;
}