@import "sass/variables";
@import "sass/mixins";

.footer {
  padding: 30px;
  color: white;
  background-color: $secondary;
  min-height: 75px;
  border-top: .09rem solid white;
}

.navbar-center {
  max-width: 100%;
}
