@import "sass/variables";

.btn-contact {
  float: right;
  background-color: $primary;
  color: white;
  border: none;

  &:hover {
    background-color: $secondary;
    border: 1px solid white;
  }
}
