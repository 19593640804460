@import '/mixins';

.mt-15 {
  margin-top: 15px;
}

.hide-on-xs {
  display: none;

  @include breakpoint(xs) {
    display: block;
  }
}

.primary {
  border-top: .09rem solid white;
}

.purple {
  border-top: .09rem solid $purple;
}
