@import "/variables";

@mixin breakpoint($class) {
  @if $class == xs {
    @media (min-width: $breakpoint-xs) {
      @content;
    }
  } @else if $class == sm {
    @media (min-width: $breakpoint-sm) {
      @content;
    }
  } @else if $class == md {
    @media (min-width: $breakpoint-md) {
      @content;
    }
  } @else if $class == lg {
    @media (min-width: $breakpoint-lg) {
      @content;
    }
  } @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg";
  }
}
