@import "sass/variables";
@import "sass/mixins";

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

.contact-columns {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;


  @include breakpoint(md) {
    flex-direction: row;
  }
}

.contact-column {
  color: white;
  width: 100%;

  @include breakpoint(sm) {
    width: 100%;
  }

  &--form {
    min-height: 700px;
    height: auto;
    color: $secondary;
    background-color: $light-grey;

    .contact-form-container {
      padding: 150px 60px 0;

      .contact-header {
        text-align: center;
      }
    }
  }

  &--location {
    min-height: 700px;
    height: auto;
    background-color: $secondary;

    .contact-form-container {
      padding: 150px 60px 0;

      .contact-header {
        text-align: left;
        margin-bottom: 40px;
      }
    }
  }

  &--map {
    min-height: 700px;
    height: auto;

    div,
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.contact-image {
  height: auto;
  max-height: 800px;
  width: 100%;

  @include breakpoint(md) {
    width: 100%;
  }
}

.contact-info {
  display: flex;
  align-items: center;
  margin-bottom: 50px;

  div {
    margin-left: 15px;
    max-width: 70%;
  }

  a {
    color: orange;
  }
}
