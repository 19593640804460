@import "sass/mixins";

.parallax {
  height: 700px;
  position: relative;
  overflow: hidden;

  img {
    width: 250%;
    height: auto;
    position: absolute;

    @include breakpoint(lg) {
      width: 100%;
    }
  }
}
